<template>
  <div class="main">
    <div class="exam flex between" v-if="status == 1">
      <div class="index-top-right">
        <div class="top-ri-tit">网站快速通道</div>
        <div class="top-ri-con">
          <div class="ri-con-nav flex pointer" @click="toUrl('/exam')">
            <img src="@/assets/a-bg.png" class="nav-bg" />
            <img src="@/assets/a-1.png" class="nav-icon" />
            <div class="con-nav-tit">
              <div class="con-nav-tit1">考试报名</div>
              <div class="con-nav-tit2">Examination registration</div>
            </div>
          </div>
          <div class="ri-con-nav flex pointer" @click="toUrl('/print')">
            <img src="@/assets/a-bg.png" class="nav-bg" />
            <img src="@/assets/a-2.png" class="nav-icon" />
            <div class="con-nav-tit">
              <div class="con-nav-tit1">打印准考证</div>
              <div class="con-nav-tit2">Print admission ticket</div>
            </div>
          </div>
          <div class="ri-con-nav flex pointer" @click="toUrl('/score')">
            <img src="@/assets/a-bg2.png" class="nav-bg" />
            <img src="@/assets/a-3.png" class="nav-icon" />
            <div class="con-nav-tit">
              <div class="con-nav-tit1">成绩查询</div>
              <div class="con-nav-tit2">Score query</div>
            </div>
          </div>
        </div>
      </div>
      <div class="exam-right">
        <div class="score-login" style="position: relative">
          <div class="login-tit">成绩查询</div>
          <div class="flex between tabs">
            <!-- <div :class="active == 1 ? 'tabs-a' : ''" @click="toActive(1)">
              查询方法一
            </div>
            <div :class="active == 2 ? 'tabs-a' : ''" @click="toActive(2)">
              查询方法二
            </div> -->
          </div>
          <div class="login-form">
            <div class="flex login-form-item login-form-item2">
              <div class="item-tit">考试名称：</div>
              <div class="item-inputs">
                <el-select
                  v-model="exam_id"
                  filterable
                  placeholder="请选择考试名称"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <span>*</span>
              </div>
            </div>
            <div class="flex login-form-item login-form-item2">
              <div class="item-tit">考试岗位：</div>
              <div class="item-inputs">
                <el-select
                  v-model="position_id"
                  filterable
                  placeholder="请选择考试岗位"
                  @visible-change="changePosi"
                >
                  <el-option
                    v-for="item in options2"
                    :key="item.id"
                    :label="item.name+'（岗位代码：'+item.code+')'"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <span>*</span>
              </div>
            </div>
            <!-- <div
              class="flex login-form-item login-form-item2"
            >
              <div class="item-tit">考试类型：</div>
              <div class="item-inputs">
                <el-select
                  v-model="exam_type"
                  filterable
                  placeholder="请选择考试类型"
                >
                  <el-option
                    v-for="item in options3"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <span>*</span>
              </div>
            </div> -->
            <!-- <div
              class="flex login-form-item login-form-item2"
              v-if="active == 1"
            >
              <div class="item-tit">准考证号：</div>
              <div class="item-inputs">
                <el-input
                  style="width: 50%"
                  v-model="examinee_number"
                  placeholder="请输入准考证号"
                  @keyup.enter.native="addByEnterKey"
                ></el-input>
                <span>*</span>
                <span>（面试请输入抽签号）</span>
              </div>
            </div> -->
            <div class="flex login-form-item login-form-item2">
              <div class="item-tit">姓名：</div>
              <div class="item-inputs">
                <el-input
                  v-model="name"
                  placeholder="请输入姓名"
                  @keyup.enter.native="addByEnterKey"
                ></el-input>
                <span>*</span>
              </div>
            </div>
            <div class="flex login-form-item">
              <div class="item-tit">请输入证件号：</div>
              <div class="item-inputs">
                <el-input
                  v-model="id_number"
                  placeholder="请输入证件号"
                  @keyup.enter.native="addByEnterKey"
                ></el-input>
                <!-- <input
                  placeholder="请输入证件号："
                  v-model="id_number"
                /> -->
                <span>*</span>
              </div>
            </div>
            <div class="flex login-form-item login-form-item2">
              <div class="item-tit">请输入图形验证码：</div>
              <div class="item-inputs">
                <el-input
                  v-model="captcha"
                  placeholder="请输入图形验证码"
                  @keyup.enter.native="addByEnterKey"
                ></el-input>
                <!-- <input
                  placeholder="请输入图形验证码"
                  v-model="captcha"
                  @keydown="addByEnterKey"
                /> -->
                <span>*</span>
                <img :src="codeImg" class="pointer" @click="getCodeImg" />
              </div>
            </div>
            <!-- 按钮 -->
            <div class="form-btn flex between">
              <div class="pointer" @click="toSearch">查询</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="status == 2" class="status2">
      <div class="finger border-bg">
        <div class="border-tit">当前位置 > 成绩查询 > 查询结果</div>
        <div class="result-tit">成绩查询结果</div>
        <table v-if="info != ''">
          <tr class="titColor">
            <th colspan="4">考 生 信 息</th>
          </tr>
          <tr>
            <td class="width">姓 名：</td>
            <td class="width">{{ info.name }}</td>
            <td class="width">证件号码：</td>
            <td class="width">{{ info.id_number }}</td>
          </tr>
          <tr>
            <td>准考证号：</td>
            <td>{{ info.examinee_number }}</td>
            <td>报考岗位：</td>
            <td>{{ info.position_name }}</td>
          </tr>
          <!-- <tr>
            <td colspan="2" class="titColor">考试科目</td>
            <td colspan="2" class="titColor center">{{ info.exam_subject }}</td>
          </tr> -->
          <tr v-if="info.score!=''&&info.score!=null">
            <td colspan="2" class="center" >笔试成绩</td>
            <td colspan="2" class="center">{{ info.score }}</td>
          </tr>
          <tr v-if="info.score2!=''&&info.score2!=null">
            <td colspan="2" class="center" >面试成绩</td>
            <td colspan="2" class="center">{{ info.score2 }}</td>
          </tr>
          <tr v-if="info.balance_coefficient!=''&&info.balance_coefficient!=null">
            <td colspan="2" class="center">平衡系数</td>
            <td colspan="2" class="center">{{ info.balance_coefficient }}</td>
          </tr>
          <tr v-if="info.final_score!=''&&info.final_score!=null">
            <td colspan="2" class="center">面试最终成绩</td>
            <td colspan="2" class="center">{{ info.final_score }}</td>
          </tr>
          <tr v-if="info.score3!=''&&info.score3!=null">
            <td colspan="2" class="center">综合成绩</td>
            <td colspan="2" class="center">{{ info.score3 }}</td>
          </tr>
          <!-- <tr class="titColor">
            <td>考试科目</td>
            <td colspan="1" v-if="info.score!=''&&info.score!=null">笔试成绩</td>
            <td colspan="1" v-if="info.score2!=''&&info.score2!=null">面试成绩</td>
            <td colspan="1" v-if="info.balance_coefficient!=''&&info.balance_coefficient!=null">平衡系数</td>
            <td colspan="1" v-if="info.final_score!=''&&info.final_score!=null" class="width">面试最终成绩</td>
            <td colspan="1" v-if="info.score3!=''&&info.score3!=null">综合成绩</td>
          </tr>
          <tr class="center">
            <td>{{ info.exam_subject }}</td>
            <td colspan="1" v-if="info.score!=''&&info.score!=null">{{ info.score }}</td>
            <td colspan="1" v-if="info.score2!=''&&info.score2!=null">{{ info.score2 }}</td>
            <td colspan="1" v-if="info.balance_coefficient!=''&&info.balance_coefficient!=null">{{ info.balance_coefficient }}</td>
            <td colspan="1" v-if="info.final_score!=''&&info.final_score!=null">{{ info.final_score }}</td>
            <td colspan="1" v-if="info.score3!=''&&info.score3!=null">{{ info.score3 }}</td>
          </tr> -->
        </table>
        <div class="btn pointer" @click="toReturn">返回</div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryScore, examLists, getExamPostion } from "@/api/apiData";
export default {
  data() {
    return {
      currentPage4: 4,
      codeImg: "",
      id_number: "",
      examinee_number: "",
      captcha: "",
      status: 1,
      info: "",
      active: 1,
      name: "",
      options: [],
      options2: [],
      options3: [
        {
          id: 1,
          name: "笔试",
        },
        {
          id: 2,
          name: "面试",
        },
      ],

      exam_id: "",
      position_id: "",
      exam_type: "",
    };
  },
  mounted() {
    this.$bus.emit("navS", 5);
    this.getCodeImg();
    this.getExamList();
  },
  methods: {
    // 考试列表
    getExamList() {
      examLists().then((res) => {
        console.log(res);
        this.options = res.data.list;
      });
    },
    changePosi(e) {
      console.log(e);
      console.log(this.exam_id);
      if (this.exam_id == "" && e) {
        this.$message.error("请先选择考试名称");
      } else {
        getExamPostion({ exam_id: this.exam_id }).then((res) => {
          this.options2 = res.data;
        });
      }
    },
    getCodeImg() {
      let url =
        "https://gxrcexam.nccloudmedia.com/index.php/api/common/captcha?rand=" +
        Math.random();
      // let url =
      //   "http://192.168.1.158:8086/api/api/common/captcha?rand=" +
      //   Math.random();
      this.codeImg = url;
    },
    addByEnterKey(e) {
      if (e.keyCode == 13) {
        this.toSearch();
      }
    },
    // 标签切换
    toActive(n) {
      this.active = n;
    },
    toSearch() {
      // let obj = {
      //   id_number: this.id_number,
      //   examinee_number: this.examinee_number,
      //   captcha: this.captcha,
      // };
      // if (this.active == 2) {
      let obj = {
        id_number: this.id_number,
        exam_id: this.exam_id,
        position_id: this.position_id,
        name: this.name,
        // exam_type: this.exam_type,
        captcha: this.captcha,
      };
      // }
      if (obj.exam_id == "") {
        this.$message.error("请选择考试名称");
        return false;
      }
      if (obj.position_id == "") {
        this.$message.error("请选择考试岗位");
        return false;
      }
      //  if (obj.exam_type == "") {
      //   this.$message.error("请选择考试类型");
      //   return false;
      // }
      if (obj.name == "") {
        this.$message.error("请输入姓名");
        return false;
      }

      if (obj.id_number == "") {
        this.$message.error("请输入证件号");
        return false;
      }
      if (obj.captcha == "") {
        this.$message.error("请输入图形验证码");
        return false;
      }
      queryScore(obj).then((res) => {
        if (res.code == 1) {
          this.info = res.data;
          this.status = 2;
        } else {
          this.$message.error(res.msg);
          this.getCodeImg();
        }
      });
    },
    toReturn() {
      this.status = 1;
    },
    toUrl(u) {
      this.$router.push(u);
    },
  },
};
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.exam {
  margin-top: 20px;
  .index-top-right {
    width: 295px;
    .top-ri-tit {
      font-size: 14px;
      background: #58beff;
      color: white;
      width: fit-content;
      padding: 5px 10px;
    }
    .top-ri-con {
      background: #e5f5ff;
      padding: 18px 20px;
      .ri-con-nav {
        position: relative;
        width: 255px;
        height: 70px;
        margin-bottom: 20px;
        .nav-bg {
          width: 100%;
          height: 100%;
          position: absolute;
        }
        .nav-icon {
          width: 40px;
          height: 40px;
          position: absolute;
          left: 16px;
          top: 16px;
        }
        .con-nav-tit {
          position: absolute;
          color: white;
          top: 15px;
          left: 72px;
          width: 80%;
          .con-nav-tit1 {
            font-size: 18px;
          }
          .con-nav-tit2 {
            font-size: 10px;
          }
        }
      }
      .ri-con-nav:nth-child(3) {
        margin-bottom: 0;
      }
    }
  }
  .exam-right {
    width: 73%;
    .exam-right-tit {
      color: #0089e1;
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
    }
    .block {
      text-align: center;
      margin-top: 20px;
    }
  }
}
.score-login {
  width: 80%;
  margin: 30px auto 40px;
  background: linear-gradient(to bottom, #d2f4ff, #ffffff);
  border: dashed 1px rgb(88, 87, 87);
  .login-tit {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding: 20px 0 10px;
    // border-bottom: solid 3px rgb(151, 150, 150);
    width: 80%;
    margin: 0 auto;
  }
  .login-form {
    width: 80%;
    text-align: center;
    margin: 0 auto;
    font-size: 18px;
    padding: 20px 0 20px;
    .login-form-item {
      justify-content: center;
      align-items: center;
      line-height: 3;
      input {
        padding: 5px;
      }
      span {
        color: #ff1919;
        margin-left: 5px;
      }
      .item-tit {
        width: 30%;
        text-align: right;
      }
      .item-inputs {
        width: 70%;
        text-align: left;
        display: flex;
        align-items: center;
      }
      img {
        width: 40%;
      }
    }
    .form-btn {
      width: 25%;
      margin: 30px auto 20px;
      div {
        background: linear-gradient(to bottom, #d6edfe, #79c4fb);
        color: #005791;
        padding: 8px 25px;
        border: solid 1px;
      }
    }
  }
}
.status2 {
  width: 90%;
  margin: 0 auto;
  .finger {
    margin-top: 20px;
  }
  .result-tit {
    text-align: center;
    color: #666a69;
    font-size: 20px;
    font-weight: bold;
    line-height: 3;
  }
  table {
    border-collapse: collapse;
    width: 80%;
    margin: 0 auto;
  }

  table,
  td,
  th {
    border: 1px solid #eeeeee;
    font-size: 14px;
    line-height: 2.5;
    padding: 5px 10px;
  }
  .titColor {
    color: #666a69;
    font-size: 14px;
    background: #eef8ff;
    text-align: center;
  }
    .titColor2 {
    background: #f9f9f9;
  }
  .center {
    text-align: center;
  }
  .width {
    width: 25%;
  }
  .btn {
    background: linear-gradient(to bottom, #d6edfe, #79c4fb);
    color: #005791;
    padding: 5px 25px;
    border: solid 1px;
    width: fit-content;
    margin: 30px auto 20px;
    font-size: 14px;
  }
}
.tabs {
  width: 80%;
  margin: 0 auto;
  font-weight: bold;
  font-size: 18px;
  div {
    text-align: center;
    width: 45%;
    border-bottom: solid 2px gray;
    padding-bottom: 10px;
    cursor: pointer;
  }
  .tabs-a {
    border-bottom: solid 2px #0089e1;
    color: #0089e1;
  }
}
</style>